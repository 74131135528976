
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import EslSelectionDialog from '../dialog/EslSelectionDialog.vue';
import HttpMixin from '@/shared/http/http.mixin';
import { Nullable } from '@/shared/app/app.utils';
import { EslConfig } from '@/shared/store/models/EslConfig';
import WithSnackMixin from '@/shared/app/mixins/with-snack.mixin';
import { SnackType } from '@/shared/app/app.constants';
import { EslInfo } from '@/shared/store/models/EslInfo';

@Component({
  components: { EslSelectionDialog },
})
export default class EslCloningCard extends mixins(Vue, HttpMixin, WithSnackMixin) {
  @Prop({ default: null })
  eslConfig!: Nullable<EslConfig>;

  @Prop({ default: () => [] })
  eslConfigs!: EslConfig[];

  @Prop({ default: () => [] })
  eslInfos: EslInfo[] = [];

  @Prop({ default: null })
  eslInfo!: Nullable<EslInfo>;

  async onMounted() {
    console.log(this.eslInfo);
    console.log(this.eslConfigs);
  }

  // @Watch('eslConfigs')
  // onEslConfigsChange(eslConfigs: EslConfig[]) {
  //   console.log("eslConfigs changed", eslConfigs);
  // }


  async updateEnable(cloneEnable: boolean) {
    this.loading = true;
    if (this.eslInfo) {
      const eslInfo = this.eslInfo;
      await this.http.changeEslCloneEnableStatus(eslInfo.labelCode, cloneEnable).then(
        () => {
          this.snackSuccess();
          if (eslInfo) eslInfo.cloneEnable = cloneEnable;
        },
        () => {
          this.snack({
            text: 'Erreur, mise à jour impossible',
            type: SnackType.ERROR,
          });
        },
      );
    }

    this.loading = false;
  }

  addTarget(target: any) {
    console.log(target)
    if (this.eslInfo && target.eslInfo)
      if (this.eslInfo.cloneTargets) {
        if (this.eslInfo.cloneTargets.find((lc) => lc === target.eslInfo.labelCode)) return;
        this.eslInfo.cloneTargets.push(target.eslInfo.labelCode);
        this.pushTargets();
      }
  }

  removeTarget(index: number) {
    if (this.eslInfo) {
      if (!this.eslInfo.cloneTargets) this.eslInfo.cloneTargets = [];
      this.eslInfo.cloneTargets.splice(index, 1);
      this.pushTargets();
    }
  }

  private async pushTargets() {
    this.loading = true;
    if (!this.eslInfo || !this.eslConfig) return;
    await this.http
      .updateEslCloneTargets(this.eslConfig.labelCode, this.eslInfo.cloneTargets || [])
      .then(
        () => {
          const index = this.eslInfos.findIndex(i => i.labelCode === this.eslConfig?.labelCode)
          if (index > -1) {
            this.eslInfos[index].cloneTargets = this.eslInfo?.cloneTargets;
          }
          this.snackSuccess();
        },
        () => {
          this.snack({
            text: 'Erreur, mise à jour impossible',
            type: SnackType.ERROR,
          });
        },
      );
    this.loading = false;
  }

  private snackSuccess() {
    this.snack({
      type: SnackType.SUCCESS,
      text: 'Paramètre mis à jour',
    });
  }

  useCode(labelCode: string) {
    return this.eslConfigs.find((eslConfig) => eslConfig.labelCode === labelCode)?.useCode || '???';
  }

  loading = false;
  dialog = false;
}
